import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		beforeEnter: AuthRequired,
		children: [
			{
				name: 'dashboard',
				path: 'page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
				beforeEnter: AuthRequired,
			},
			{
				name: 'manager',
				path: '/page/app/manager/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/manager'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'products',
				path: '/page/app/products/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/products'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'users',
				path: '/page/app/users/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'administrator',
				path: '/page/app/administrator',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/administrator'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'fiton',
				path: '/page/app/fiton',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/fiton'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs',
				path: '/page/app/customs/:id',
				redirect: '/page/app/customs',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs/task'),
				beforeEnter: AuthRequired,
				children: [
					{ name: 'customs', path: 'form', component: () => import('./views/app/customs/form') },
					{ name: 'customs', path: 'messages', component: () => import('./views/app/customs/messages') },
				],
			},
			{
				name: 'customs',
				path: '/page/app/customs',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs-operations',
				path: '/page/app/customs/operations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs/operations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs-settings',
				path: '/page/app/customs-settings',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs-settings'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs-b02',
				path: '/page/app/customs/b02/details/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs/b02-details'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs-b02',
				path: '/page/app/customs/b02',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs/b02'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'customs-dutytaxfee',
				path: '/page/app/customs/dutytaxfee',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customs/dutytaxfee'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'douaneDetails',
				path: '/page/app/douane/:dossierId',
				component: () => import(/* webpackChunkName: "douane details" */ './views/app/douane/details'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'douane',
				path: '/page/app/douane',
				component: () => import(/* webpackChunkName: "douane" */ './views/app/douane'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'dossierDetails',
				path: '/page/app/dossier/:dossierId',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/dossier/details'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'dossier',
				path: '/page/app/dossier',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/dossier'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'discrepancies',
				path: '/page/app/discrepancies',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/discrepancies'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'discrepancyDetails',
				path: '/page/app/discrepancy/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/discrepancies/details'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'translations',
				path: '/page/app/translations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/translations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'helpbutton',
				path: '/page/app/helpbutton',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/helpbutton'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'tasks',
				path: '/page/app/tasks',
				component: () => import('./views/app/tasks'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'relations',
				path: '/page/app/relations/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/relations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'email-templates',
				path: '/page/app/email-templates',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/email-templates'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'emails',
				path: '/page/app/emails',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/emails'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'transport-units',
				path: '/page/app/transport-units',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/transport-units'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'stock-mutations',
				path: '/page/app/stock-mutations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/stock-mutations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'warehouse-positions',
				path: '/page/app/warehouse-positions',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/warehouse-positions'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'available-warehouse-positions',
				path: '/page/app/available-warehouse-positions',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/available-warehouse-positions'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'warehouses',
				path: '/page/app/warehouses',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/warehouses'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'product-stocks',
				path: '/page/app/product-stocks',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/product-stocks'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'invoicing',
				path: '/page/app/invoicing',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoicing'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'invoices',
				path: '/page/app/invoices',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoices'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'remarks',
				path: '/page/app/remarks',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/remarks'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'logs',
				path: '/page/app/logs',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/logs'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'transport-unit-stock',
				path: '/page/app/transport-unit-stock',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/transport-unit-stock'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'documents',
				path: '/page/app/documents',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/documents'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'task-reports',
				path: '/page/app/task-reports',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/task-reports'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'warehouse-reports',
				path: '/page/app/warehouse-reports',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/warehouse-reports'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'contracts',
				path: '/page/app/contracts',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/contracts'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'transport-unit-history',
				path: '/page/app/transport-unit-history',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/transport-unit-history'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'field-validations',
				path: '/page/app/field-validations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/field-validations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'rights',
				path: '/page/app/rights',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/rights'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'locations',
				path: '/page/app/locations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/locations'),
				beforeEnter: AuthRequired,
			},
			{
				name: 'notifications',
				path: '/page/app/notifications/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/notifications')
			},
			{
				name: 'invoice',
				path: '/page/app/invoice-edit/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/invoice-edit')
			},
			{
				name: 'operator-overview',
				path: '/page/app/operator-overview/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/operator-overview')
			},
			{
				name: 'kiosk_overviews',
				path: 'page/app/kiosk',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/kiosk')
			},
			{
				name: 'crons',
				path: 'page/app/crons',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/crons')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ name: 'login', path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{
		name: 'Public',
		path: '/public',
		component: () => import('./views/public'),
		children: [
			{
				path: 'arrival',
				component: () => import(
					'./views/public/arrival'
				),
				children: [
					{ path: ':token', component: () => import('./views/public/arrival') },
				],
			}
		]
	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
