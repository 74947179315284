export default (to, from, next) => {
	if (localStorage.getItem('user') != null && localStorage.getItem('user').length > 0) {
		//set params
		let user = localStorage.getItem('user');
		let authenticated = false;
		let isDashboardAvailable = false;

		//check if user is a string
		if (typeof user == 'string') {
			user = JSON.parse(user);
		}
		
		//check if rights are not undefined
		if (user.rights != undefined && user.rights.menu != undefined) {
			//check if next item is not undefined
			if (to != undefined && to.name != undefined) {
				let arrMenuRights = Object.keys(user.rights.menu);
				
				if (arrMenuRights.includes(to.name)) {
					authenticated = true;
				}
				
				if (arrMenuRights.includes('dashboard')) {
					isDashboardAvailable = true;
				}
			}
		}

		//check for authenticated
		if (authenticated) {
			next();
		} else if (isDashboardAvailable) {
			next('/page/app/dashboard?notAuthenticated=' + to.name);
		} else {
			next('/page/user/login');
		}
	} else {
		localStorage.removeItem('user')
		next('/page/user/login')
	}
}
