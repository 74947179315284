<template>
	<div class="h-100">
		<router-view v-if="isLanguageLoaded"/>
	</div>
	<div v-if="environment">
		<nav class="development-footer">
			<span class="development-footer-text" v-for="index in 100" :key="index">⚠ {{ environment }} - </span>
		</nav>
	</div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { getDirection } from '@/utils'
import moment from 'moment';

export default {
	name: 'App',
	data () {
		return {
			user: false,
			environment: false,
			testEnvironment: false,
		}
	},
	created () {
		localStorage.setItem('apiURL', process.env.VUE_APP_API_URL);
		let url = window.location.href;
		this.environment = null;
		if (url.includes('.acc') || this.testEnvironment) {
			this.environment = localStorage.getItem('currentLanguage') == 'nl' ? 'Acceptatie' : 'Acceptance';
		}
	},
	computed: {
		isLanguageLoaded: function() {
			return this.$i18n.availableLocales.length > 0;
		}
	},
	methods: {
		...mapActions(['logout']),
	},
	beforeMount () {
		const direction = getDirection()
		if (direction.isRtl) {
			document.body.classList.add('rtl')
			document.dir = 'rtl'
			document.body.classList.remove('ltr')
		} else {
			document.body.classList.add('ltr')
			document.dir = 'ltr'
			document.body.classList.remove('rtl')
		}
	}
}
</script>
